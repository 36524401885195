import React from "react";
import BeltCard from "../components/BeltCard";
import { useSelector } from "react-redux";
import "../firebase";

const BeltList = () => {
  const { sporcular } = useSelector((state) => state.sporcular);

  const sabit = [...sporcular];

  const sporcu_kemerler = sabit.map((sporcu) => sporcu.dan);

  let unique_belts = [...new Set(sporcu_kemerler)];

  return (
    <section className="container flex gap-20 md:mx-16 justify-evenly">
      <div>
        <h1 className="pt-8 pl-4 mb-3 text-2xl font-bold text-write">
          Kemer Listesi
        </h1>
        {unique_belts.map((belt) => (
          <BeltCard belt={belt} />
        ))}
      </div>
      <div className="items-center hidden lg:flex lg:flex-col gap-y-8">
        <img
          className="object-cover mt-10 w-96"
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQwboDX1FK4sGpddUdeGL5-k2EG5xPbYNINqA&usqp=CAU"
          alt="tasarım"
        />
        <img
          className="object-cover mt-4 w-80"
          src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRScotx8IuofvIUBUGipCIgZylZblvEHExXaA&usqp=CAU"
          alt="tasarım"
        />
      </div>
    </section>
  );
};

export default BeltList;
